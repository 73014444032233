import React, {
	ChangeEvent,
	Dispatch,
	SetStateAction,
	useState,
	useEffect,
	useContext,
} from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';

import Header from '../Header';
import { useWebBrand } from '../WebBrand';
import LoginForm from './LoginForm';
import { LostPasswordForm } from '../Password';
import LoginContext from './LoginContext';

import './LoginComponent.scss';
import LoginTroubleshooting from './LoginTroubleshooting';

type LoginProps = {
	errorMessage?: string;
	performLogin: (userName: string, password: string, otp: string, branch: string) => void;
	supportUrl?: string;
};

const LoginComponent: React.FC<LoginProps> = ({
	performLogin,
	errorMessage,
	supportUrl,
}: LoginProps) => {
	const { webBrand } = useWebBrand();
	const [showLostPasswordForm, setShowLostPasswordForm] = useState(false);
	const [showTroubleshootingPage, setShowTroubleshootingPage] = useState(false);
	const { setError } = useContext(LoginContext);

	useEffect(() => {
		setError(errorMessage);
	}, []);

	const updateValue =
		(setter: Dispatch<SetStateAction<string>>) =>
		(e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>): void => {
			if (e.currentTarget.value) {
				setter(e.currentTarget.value);
			} else {
				setter('');
			}
		};

	const clickOnLostPassword = (): void => {
		setShowLostPasswordForm(true);
		setError('');
	};

	const clickOnCannotLogin = (): void => {
		setShowTroubleshootingPage(true);
		setError('');
	};

	const returnToLogin = (): void => {
		setShowTroubleshootingPage(false);
		setError('');
	};

	const clickOnUnsuspendUser = async (): Promise<void> => {
		setError('');
	};

	let loginContent;
	if (showTroubleshootingPage) {
		loginContent = (
			<LoginTroubleshooting supportUrl={supportUrl} returnAction={returnToLogin} />
		);
	} else if (showLostPasswordForm) {
		loginContent = <LostPasswordForm onClose={() => setShowLostPasswordForm(false)} />;
	} else {
		loginContent = (
			<>
				<LoginForm
					updateValue={updateValue}
					performLogin={performLogin}
					webBrand={webBrand}
					error={errorMessage}
					supportUrl={supportUrl}
					clickOnUnsuspendUser={clickOnUnsuspendUser}
				/>
				<div className="d-grid">
					<Button variant="link" onClick={clickOnLostPassword}>
						Forgot Password ?
					</Button>
				</div>
				<div className="d-grid">
					<Button variant="link" onClick={clickOnCannotLogin}>
						Cannot login ?
					</Button>
				</div>
			</>
		);
	}

	return (
		<>
			<Header />
			<Container className="mt-4">
				<Row>
					<Col md={{ span: 4, offset: 4 }}>
						<Card>
							<Card.Body>
								<Card.Title>
									<div className="brand-area">
										<img
											src={webBrand.iconUrl}
											alt={webBrand.name}
											className="brand-img"
										/>
										<div className="brand-title mt-4">{webBrand.name}</div>
										<div className="brand-sub-title mt-2">
											{webBrand.subtitle}
										</div>
									</div>
								</Card.Title>
								{loginContent}
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default LoginComponent;
