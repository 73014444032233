import React, { PropsWithChildren } from 'react';
import { NetworkErrorStatus, TunzNetworkError } from '@tunz/http';
import { Loading } from '@tunz/ifs-react-lib';

import App from './App';
import { LoginContext, LoginProvider, useUserInfo } from './Components/Login';
import MicroFrontendPropsContext from './Services/ModuleService/MicroFrontendPropsContext';
import useMFEProps from './Components/Portal/useMFEProps';

import { WebBrandProvider } from './Components/WebBrand';
import OTPInput from './Components/OTPInput';
import HttpClientHelper from './Utils/HttpClientHelper';
import BOLink from './Components/BOLink';
import RouterLinkFormatter from './Components/RouterLinkFormatter';
import SUPPORT_URL from './Components/Login/Constants';
import RouterLinkFormatterV2 from './Components/RouterLinkFormatterV2';

type Props = PropsWithChildren;
const MfePortal: React.FC<Props> = ({ children }: Props) => (
	<WebBrandProvider>
		<LoginProvider supportUrl={SUPPORT_URL}>
			<App>{children}</App>
		</LoginProvider>
	</WebBrandProvider>
);

export default MfePortal;
export {
	MicroFrontendPropsContext,
	LoginContext,
	useUserInfo,
	useMFEProps,
	HttpClientHelper,
	OTPInput,
	BOLink,
	RouterLinkFormatter,
	RouterLinkFormatterV2,
	Loading,
	TunzNetworkError,
	NetworkErrorStatus,
};
export {
	AcquirerFormatter,
	BranchFormatter,
	CurrencyRenderer,
	EndpointFormatter,
	PaymentMethodFormatter,
	ProductFormatter,
	ReferentialSelector,
	ServiceFormatter,
	useReferential,
	getAcquirers,
	getBranches,
	getCurrencies,
	getEndpoints,
	getPaymentMethods,
	getProducts,
	getServices,
} from '@tunz/referentials';
