import React, { PropsWithChildren } from 'react';

import { ToastContainer } from '@tunz/message-displayer';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Portal from '../Components/Portal';
import { LoginRequired } from '../Components/Login';

import { useWebBrand } from '../Components/WebBrand';
import './App.scss';
import PasswordResetForm from '../Components/Password/PasswordResetForm';
import UnsuspendUserForm from '../Components/Unsuspend/UnsuspendUserForm';

type Props = PropsWithChildren;

const App: React.FC<Props> = ({ children }: Props) => {
	const { webBrand } = useWebBrand();

	return (
		<>
			<ToastContainer
				position="top-right"
				style={{ marginTop: '60px' }}
				autoClose={5000}
				closeOnClick={false}
				closeButton
				pauseOnHover
			/>
			<Router>
				<Routes>
					<Route
						path="/*"
						element={
							<LoginRequired>
								<Portal>{children}</Portal>
							</LoginRequired>
						}
					/>
					<Route path="/resetPassword" element={<PasswordResetForm />} />
					<Route path="/unsuspenduser" element={<UnsuspendUserForm />} />
				</Routes>
			</Router>
			<footer className="footer">
				<div className="container">
					<div className="text-muted text-center">
						{webBrand && (
							<div>
								Get support: {webBrand.phone} -{' '}
								<a href={`mailto:${webBrand.email}`}>{webBrand.email}</a>
							</div>
						)}
					</div>
				</div>
			</footer>
		</>
	);
};

export default App;
