import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import { displaySuccess } from '@tunz/message-displayer';
import ErrorDisplay from '../ErrorDisplay';
import { loadWebBrandDetails } from '../../Services/WebBrandService';
import QueryString from '../../Utils/QueryString';
import Header from '../Header';
import { useWebBrand } from '../WebBrand';
import { executeUnsuspendUser } from '../../Services/UserService/UnsuspendRequest';

const UnsuspendUserForm: React.FC = () => {
	const navigate = useNavigate();
	const qs = QueryString.fromCurrentPage();
	const token = qs.get('token');
	const { webBrand } = useWebBrand();

	const [errorMessage, setErrorMessage] = React.useState('');

	const handleCloseButtonClick = (): void => {
		setErrorMessage('');
		navigate('/');
	};

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
		e.preventDefault();
		setErrorMessage('');

		const formData = new FormData(e.currentTarget);
		const login = formData.get('login') as string;
		const password = formData.get('password') as string;
		const otp = formData.get('otp') as string | undefined;
		try {
			const brandDetail = await loadWebBrandDetails();
			const branch = brandDetail.id;
			await executeUnsuspendUser(token, {
				branch,
				login,
				password,
				otp,
			});

			if (token) {
				displaySuccess(
					'Your account has been unsuspended. Please change your password on next login.',
				);
			} else {
				displaySuccess('An email has been sent to unsuspend your user.');
			}
		} catch (err) {
			const error = err as Error;
			setErrorMessage(error.message);
		}
	};

	return (
		<>
			<Header />

			<Container className="mt-4">
				<Row>
					<Col md={{ span: 4, offset: 4 }}>
						<Card style={{ width: '400px' }} text="youpi">
							<Card.Body>
								<Card.Title>
									<div className="brand-area">
										<img
											src={webBrand.iconUrl}
											alt={webBrand.name}
											className="brand-img"
										/>
										<div className="brand-title mt-4">{webBrand.name}</div>
										<div className="brand-sub-title mt-2">
											{webBrand.subtitle}
										</div>
									</div>
								</Card.Title>

								<Form
									id="unsuspendUserForm"
									className="mt-5 mb-2"
									onSubmit={handleSubmit}
								>
									<Form.Group controlId="login" className="my-3">
										<Form.Control
											name="login"
											type="text"
											placeholder="Login"
											aria-label="login"
											data-testid="unsuspend-login-input"
											required
										/>
									</Form.Group>

									<Form.Group controlId="password" className="my-3">
										<Form.Control
											name="password"
											type="password"
											placeholder="Password"
											aria-label="password"
											data-testid="unsuspend-password-input"
											required
										/>
									</Form.Group>

									<Form.Group controlId="otp" className="my-3">
										<Form.Control
											name="otp"
											type="text"
											placeholder="OTP"
											aria-label="otp"
											data-testid="unsuspend-otp-input"
										/>
									</Form.Group>

									<div id="unsuspendUserButtons">
										<Button
											variant="secondary"
											onClick={handleCloseButtonClick}
											size="lg"
											data-testid="unsuspend-close-button"
										>
											Close
										</Button>
										<Button
											variant="primary"
											size="lg"
											type="submit"
											data-testid="unsuspend-button"
										>
											{token ? 'Unsuspend User' : 'Send Email'}
										</Button>
									</div>
									{errorMessage && (
										<div className="mt-3">
											<ErrorDisplay message={errorMessage} />
										</div>
									)}
								</Form>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default UnsuspendUserForm;
