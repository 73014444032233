import React from 'react';
import { Button } from 'react-bootstrap';

type Props = {
	supportUrl?: string;
	returnAction?: () => void;
};

const LoginTroubleshooting: React.FC<Props> = ({ supportUrl, returnAction }: Props) => (
	<div className="container">
		<div className="text-muted text-center">
			Having trouble logging in?
		</div>
		<div className="text-primary">
			Here are the steps to try:
		</div>
		<div className="text-body">
			<ol>
				<li>First, try to reset your password.</li>
				<li>If password reset does not work, activate your account.</li>
				<li>If you cannot activate the account,
					ensure your OTP authenticator app is working correctly-verify
					if the clock on your mobile phone is synchronised with the clock on your computer.
				</li>
				<li>If Activate account does not work for you,
					create a ticket explaining the problem <a href={supportUrl}>here</a>
				</li>
				<li>If you have no access to JIRA and all previous points did not help,
					ask for support via email on <a href="dl-gopay-tunz@worldline.com">dl-gopay-tunz@worldline.com</a>
				</li>
			</ol>
			<Button onClick={returnAction}>
				Return to login page
			</Button>

		</div>
	</div>
	);
export default LoginTroubleshooting;
