import HttpClient, { Axios } from '@tunz/http';
import { POST_UNSUSPEND_USER } from '../Constants';

export interface UnsuspendUserData {
	branch: string;
	login: string;
	password: string;
	otp?: string;
}

type UnsuspendExecutionResponse = {
	status: number;
};

export class UnsuspendError extends Error {}

export const executeUnsuspendUser = async (
	token: string | null,
	unsuspendUserData: UnsuspendUserData,
): Promise<UnsuspendExecutionResponse> => {
	try {
		const { otp, ...unsuspendUserDataWithoutOtp } = unsuspendUserData;
		const data = otp ? { ...unsuspendUserDataWithoutOtp, otp } : unsuspendUserDataWithoutOtp;
		return await HttpClient.post<UnsuspendExecutionResponse>(POST_UNSUSPEND_USER(token), data);
	} catch (e) {
		if (Axios.default.isAxiosError(e)) {
			const error = e as Axios.AxiosError<string, unknown>;
			if (error.response) {
				throw new UnsuspendError(error.response.data);
			}
		}
		const error = e as Error;
		throw new UnsuspendError(error.message);
	}
};
