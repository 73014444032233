/* eslint-disable indent */
import { FieldValueType, RecordType, RendererProps } from '@tunz/data-tables';
import React from 'react';
import { Link } from 'react-router-dom';
import { useHasPermission } from '@tunz/ifs-react-lib';
import useMFEProps from '../Portal/useMFEProps';

const sanitizeLocation = (pathname: string | undefined): string => {
	let location = '';
	if (pathname) {
		if (!pathname.startsWith('/')) {
			location += '/';
		}
		location += pathname;
		location = location.substring(0, location.lastIndexOf('/'));
	}
	return location;
};

type URLBuilder = (value: FieldValueType, record: RecordType) => string;

/**
 * RouterLinkFormatterV2
 * A higher-order function to create a link formatter component.
 */
const RouterLinkFormatterV2 =
	(
		urlBuilder: URLBuilder,
		application = '',
		permission = '',
		mfeLocation = '',
		newTab = false,
	): React.FC<RendererProps> =>
	({ value, record }: RendererProps) => {
		const { location: currentMFELocation } = useMFEProps();
		const { allowed } = useHasPermission(application, permission);

		const isLinkAllowed = (application === '' && permission === '') || allowed;

		if (value && isLinkAllowed) {
			const location = mfeLocation
				? `/${mfeLocation}`
				: sanitizeLocation(currentMFELocation?.pathname);

			return newTab ? (
				<Link
					to={`${location}${urlBuilder(value, record)}`}
					target="_blank"
					rel="noreferrer"
				>
					{value}
				</Link>
			) : (
				<Link to={`${location}${urlBuilder(value, record)}`}> {value} </Link>
			);
		}

		return <span>{value}</span>;
	};

export default RouterLinkFormatterV2;
